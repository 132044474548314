import { ReactNode, useCallback, useEffect } from 'react';
import styles from './HotelPages.module.scss';
import Header from '../../components/Header/Header';
import StoriesList from '../../components/Stories/StoriesList/StoriesList';
import StoryViewer from '../../components/Stories/StoryViewer/StoryViewer';
import Question, { IQuestion } from '../../components/Question/Question';
import Button from '../../components/Button';
import Footer from '../../components/Footer/Footer';
import SearchHotelsBar from '../../components/SearchHotelsBar/SearchHotelsBar';
import { useSelector } from 'react-redux';
import { reviewsSelector, useAppDispatch } from '../../store/store';
import FeedbackHotelsCard from '../../components/FeedbackHotelsCard/FeedbackHotelsCard';
import { useGetReviewsQuery } from '../../store/api/reviewsService';
import { setReviews } from '../../store/slices/reviews';
import { NavigationButton } from '../../components/NavigationButton/NavigationButton';
import { routes } from '../../constants/routes';
import { links } from '../../constants/links';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useLangNavigate } from '../../utils/langNavigate';


interface HotelPagesProps {
  className?: string;
  children?: ReactNode;
}


export const HotelPages = (props: HotelPagesProps) => {
  const { data: reviewsData, isLoading } = useGetReviewsQuery({ offset: 0 });
  const feedback = useSelector(reviewsSelector);
  const BASE_PRICE_HOTELS = +(process.env.REACT_APP_BASE_PRICE_HOTELS || 1399);
  const BASE_PRICE_HOTELS_EURO = +(process.env.REACT_APP_BASE_PRICE_HOTELS_EURO || 17);
  const dispatch = useAppDispatch();
  const currency = localStorage.getItem('currency') || (i18n.language.startsWith('ru') ? 'rub' : 'eur');
  const navigate = useLangNavigate();
  const { t } = useTranslation();


  const {
    className,
    children,
    ...otherProps
  } = props;

  const handleFeedbackClick = useCallback(() => {
    navigate(routes.feedback);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (reviewsData && !isLoading) dispatch(setReviews(reviewsData));
  }, [reviewsData, isLoading]);


  return (
    <div className={styles.root}>
      <section className={styles.home}>
        <div className={styles.gradientHome}>
          <img className={styles.headerhome1Icon} alt="" src="/images/header-icon-hotels.png" />
        </div>
        <div className={styles.headingsearchField}>
          <Header variant="dark" />
          <div className={styles.container}>
            <div className={styles.headingsubtitle}>
              <h1 className={styles.h1}>
                <p className={styles.p}>{t('hotels.bookingTitle1')}</p>
                <p className={styles.p1}>{t('hotels.bookingTitle2')}</p>
              </h1>
            </div>
            <NavigationButton active={'hotels'} />
            <SearchHotelsBar className={styles.searchBar} defaultOpen={true} />
          </div>
        </div>
      </section>
      <div className={styles.container}>
        <StoriesList hotels={true} />
        <StoryViewer />
        <section className={styles.div33}>
          <div className={styles.heading}>
            <h2 className={styles.h2}>
              <span>{t('hotels.howItwork')}</span>
            </h2>
          </div>
          <div className={styles.cards}>
            <div className={styles.stepCards}>
              <div className={`${styles.stepcards} ${styles.step1}`}>
                <div className={styles.headingsubtitle1}>
                  <div className={styles.div34}>{t('hotels.step1.title')}</div>
                  <div className={styles.div35}>{t('hotels.step1.subtitle')}</div>
                  <div
                    className={styles.div36}
                  >{t('hotels.step1.description')}</div>
                </div>
                <img
                  loading="lazy" className={styles.imagestepIcon} alt="image-first-step"
                  src="/images/image-first-step.png"
                />
              </div>
              <div className={`${styles.stepcards} ${styles.step2}`}>
                <div className={styles.headingsubtitle1}>
                  <div className={styles.div34}>{t('hotels.step2.title')}</div>
                  <div className={styles.div35}>{t('hotels.step2.subtitle')}</div>
                  <div className={styles.div36}>{t('hotels.step2.description')}</div>
                </div>
                <img loading="lazy" className={styles.imagestepIcon} alt="image-second-step"
                     src="/images/image-second-step.png"
                />
              </div>
            </div>
            <div className={styles.stepCards1}>
              <div className={styles.stepcardsParent}>
                <div className={styles.stepcards2}>
                  <div className={styles.headingsubtitle1}>
                    <div className={styles.div34}>{t('hotels.step3.title')}</div>
                    <div className={styles.div35}>{t('hotels.step3.subtitle')}</div>
                    <div className={styles.div36}>
                      {currency === 'rub' ?
                        t('hotels.step3.description', { price: `${BASE_PRICE_HOTELS} ₽` }) + t('hotels.step3.orThroughSBP')
                        :
                        t('hotels.step3.description', { price: `${BASE_PRICE_HOTELS_EURO} €` }) + t('hotels.step3.descriptionPart2')
                      }
                    </div>
                  </div>
                  <img loading="lazy" className={styles.imagestepIcon2} alt="image-thirth-step-cost"
                       src={
                    currency === 'rub' ?
                      '/images/image-thirth-step-cost.png':
                      '/images/image-thirth-step-cost-eng.png'
                    }/>
                </div>
                <div className={styles.stepcards3}>
                  <div className={styles.headingsubtitle1}>
                    <div className={styles.div34}>{t('hotels.step3second.title')}</div>
                    <div className={styles.div44}>{t('hotels.step3second.subtitle')}</div>
                    <div className={styles.div45}>{t('hotels.step3second.description')}</div>
                  </div>
                  <img loading="lazy" className={styles.daysIcon} alt="image-thirth-step-days"
                       src="/images/image-thirth-step-days.png" />
                </div>
              </div>
              <div className={styles.stepcards4}>
                <div className={styles.headingsubtitle5}>
                  <div className={styles.div34}>{t('hotels.step4.title')}</div>
                  <div className={styles.div47}>
                    <p className={styles.p}>{t('hotels.step4.subtitle')}</p>
                  </div>
                  <div className={styles.div48}>{t('hotels.step4.description')}</div>
                </div>
                <img loading="lazy" className={styles.imagegradientIcon} alt="" src="/images/voucher.png" />
              </div>
            </div>
          </div>
        </section>
        <section id="questions" className={styles.questions}>
          <div className={styles.heading}>
            <h2 className={styles.h21}>{t('hotels.headerQuests')}</h2>
          </div>
          <div className={styles.questions1}>
            {t<'hotels.popularQuestionsHotels', {
              returnObjects: true
            }, IQuestion[]>('hotels.popularQuestionsHotels', { returnObjects: true }).map(q => (
              <Question hotels={true} {...q} key={q.id} />
            ))}
          </div>
        </section>
        <section className={styles.help}>
          <div className={styles.heading}>
            <h2 className={styles.h22}>{t('hotels.weAreAlwaysInTouch.title')}</h2>
          </div>
          <div className={styles.helpCard}>
            <div className={styles.questions2}>
              <img loading="lazy" className={styles.imagegradientIcon1} alt=""
                   src="/images/image+gradient-questions.png" />
              <div className={styles.headingbutton}>
                <div className={styles.headingsubtitle6}>
                  <div>
                    <div className={styles.div55}>{t('hotels.weAreAlwaysInTouch.firstBlock.title')}</div>
                    <div className={styles.div56}>
                      {t('hotels.weAreAlwaysInTouch.firstBlock.subtitle')}
                    </div>
                  </div>
                  <img className={styles.imageQuestions} src="/images/Image-questions.svg" />
                </div>
                <span className={styles.telegramLink}
                >
                  <Button hotels={true} className={styles.button1}>{t('hotels.buttonReadChat')}</Button>
                </span>
              </div>
            </div>
            <div className={styles.telegram}>
              <div className={styles.headingbutton1}>
                <div className={styles.headingsubtitle7}>
                  <div className={styles.div55}>
                    <span>{t('hotels.weAreAlwaysInTouch.secondBlock.title')}</span>
                    <a className={styles.span} href={links.kupitrip_bot} target="_blank">
                      {t('hotels.weAreAlwaysInTouch.secondBlock.secondTitle')}
                    </a>
                  </div>
                  <div className={styles.div59}>{t('hotels.weAreAlwaysInTouch.secondBlock.subtitle')}</div>
                </div>
                <div className={styles.socialNetworks}>
                  <a href={links.kupitrip_bot} target="_blank">
                    <img loading="lazy" className={styles.iconButton} alt="telegram"
                         src="/images/telegram-purple.svg" />
                  </a>
                </div>
              </div>
              <div className={styles.image7}>
                <img loading="lazy" className={styles.mocupTelegramIcon} alt="" src={t('images.telegram')} />
              </div>
            </div>
          </div>
        </section>
        {feedback && feedback?.reviews.length > 0 ? (
          <section className={styles.feedback}>
            <div className={styles.headingscroll}>
              <h2 className={styles.h2}>{t('hotels.feedbackHeader')}</h2>
            </div>
            <div className={styles.feedbacks}>
              {feedback.reviews.slice(0, 3).map(f => (
                <FeedbackHotelsCard key={f.id} {...f} className={styles.homeFeedback} />
              ))}
            </div>
            <Button
              variant="outlined"
              onClick={handleFeedbackClick}
              iconAfter="/icons/arrowRight.svg"
              className={styles.button3}
            >
              {t('hotels.buttonAllFeedback')}
            </Button>
          </section>
        ) : null}
      </div>
      <Footer />
    </div>

  );
};
