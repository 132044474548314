import { ClickAwayListener, TextField } from '@mui/material';
import { useState, ChangeEventHandler, useEffect, KeyboardEventHandler, RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Airport, City, isAirport } from '../../types/ticket';
import { useSearchAirportsQuery } from '../../store/api/searchService';
import { useAppDispatch } from '../../store/store';
import { searchSelector, setSearch } from '../../store/slices/search';
import { useDebounce } from '../../utils/useDebounce';
import styles from './Autocomplete.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

export type SelectOptionType = Airport | City;

type Props = {
  className?: string;
  name: 'to' | 'from';
  label?: string;
  value?: string;
  error?: string;
  clearError?: () => void;
  hotels?: boolean
};

const Autocomplete = ({ label, name, error, clearError, hotels }: Props) => {
  const dispatch = useAppDispatch();
  const search = useSelector(searchSelector);
  const textRef = useRef<HTMLInputElement | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce((isUpdating ? value : (textRef?.current?.value.split(',')[0].trim() || value)), 300);
  const searchValue = search[name]?.name ? (search[name]?.name + (search[name]?.city && search[name]?.city !== search[name]?.name ? ', ' + search[name]?.city : '')) : '';
  const airports = useSearchAirportsQuery(debouncedValue.toLowerCase() || '');
  const [options, setOptions] = useState([]);
  const { t } = useTranslation();
  const isRussianLanguage = i18n.language.startsWith('ru');

  useEffect(() => {
    const newValue = isRussianLanguage ?
      search[name]?.name ?? search[name]?.city ?? ''
      :
      search[name]?.name_en ?? search[name]?.city_en ?? '';

    setValue(newValue);
    if (textRef) {
      // @ts-ignore
      textRef.current.value = newValue;
    }
  }, [search]);

  useEffect(() => {
    setOptions(airports.data || []);
  }, [airports.data]);

  const handleChange = (v: SelectOptionType | null) => {
    const inputValue = isRussianLanguage ?
      (v?.name ? (v?.name + (v?.city && v?.city !== v?.name ? ', ' + v?.city : '')) : '')
      :
      (v?.name_en ? (v?.name_en + (v?.city_en && v?.city_en !== v?.name_en ? ', ' + v?.city_en : '')) : '');
    if (textRef?.current) {
      textRef.current.value = inputValue;
    }
    dispatch(setSearch({ [name]: v }));
    setValue(v?.name || '');
    setIsUpdating(true);
    handleClose();
  };
  const handleFocus = () => {
    handleOpen();
    setIsUpdating(true);
    clearError && clearError();
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = (e?: MouseEvent | TouchEvent) => {
    if (textRef?.current) {
      if (e?.target === textRef.current) return;
      setIsOpen(false);
      setIsUpdating(false);
    }
  };

  const handleStartEdit = () => {
    setIsUpdating(true);
    handleOpen();
  };

  const handleTextChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const { value } = e.currentTarget;
    const clearValue = value.replace(/[^а-яА-Яa-zA-Z/-]/gi, '');
    e.currentTarget.value = clearValue;
    setValue(clearValue);
    if (!clearValue.length) {
      dispatch(setSearch({ [name]: null }));
    }
  };
  const handleKey: KeyboardEventHandler = e => {
    if (e.key == 'Tab') handleClose();
    if (e.key == 'Escape') handleClose();
  };

  const popularTo = [
    { name: 'Денпасар', city: 'Бали', code: 'DPS' },
    { name: 'Москва', city: 'Москва', code: 'MOW' },
    { name: 'Бангкок', city: 'Бангкок', code: 'BKK' }
  ];

  const popularBack = [
    { name: 'Москва', city: 'Москва', code: 'MOW' },
    { name: 'Куала-Лумпур', city: 'Куала-Лумпур', code: 'KUL' },
    { name: 'Барселона', city: 'Барселона', code: 'BCN' }
  ];

  const outputAirport = (item: Airport | City | null, index: number = 0, click: boolean = true, title: boolean = false, indexKey: string = '', titleText: string = '') => {
    return (
      <li
        className={styles.option}
        onClick={() => click && item ? handleChange(item) : null}
        style={{ minHeight: item && isAirport(item) ? 55 : 30 }}
        key={indexKey !== '' ? (indexKey + '_' + index) : index}
      >
        {title && item ? (
            <>
              <div className={styles.title}>
                <div className={styles.value}>{isRussianLanguage ? item.name : item.name_en}</div>
                {item.country ? <div
                  className={styles.description}>{isRussianLanguage ? item.country : (item.country_en ?? '')}</div> : null}
              </div>
              <div className={styles.code}>{item.code}</div>
            </>
          )
          :
          (item ? (
            <>
              <img className={styles.icon} alt="" src="/icons/plane.svg" />
              <div className={styles.title}>
                <div className={styles.label}>{isRussianLanguage ? item.name : item.name_en}</div>
              </div>
              <div className={styles.code}>{item.code}</div>
            </>
          ) : titleText)}
      </li>
    );
  };

  return (
    <div className={clsx(styles.root + ' autocomplete-wrapper-' + name, error ? styles.withError : '')}>
      <TextField
        variant="filled"
        classes={{ root: styles.textField }}
        inputRef={textRef}
        InputProps={{

          classes: { root: styles.inputRoot },
          sx: hotels? {
            '&.Mui-focused': {
              border: '1px solid var(--purple-6100FF) !important'

            }
          } : {}

        }}
        InputLabelProps={{ shrink: isUpdating || !!value || !!searchValue || !!error }}
        // value={isUpdating ? null : searchValue}
        label={label}
        onChange={handleTextChange}
        onClick={handleStartEdit}
        onKeyDown={handleKey}
        onFocus={handleFocus}
        defaultValue={searchValue}
      />
      <span className={clsx(styles.withErrorPlaceholder)}>{error}</span>
      {isOpen && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={styles.paper}>
            <ul className={styles.listbox}>
              {/*{ value.trim().length > 0 ?*/}
              {value.trim().length > 0 &&
                (
                  options && options.length
                    ?
                    options.map((item: Airport, index) => {
                      return item.type === 'city' ?
                        outputAirport(item, index, true, true)
                        :
                        outputAirport(item, index, true, false);
                    })
                    :
                    <li className={styles.option} style={{ minHeight: 30 }} key="noResults">
                      <div className={styles.title}>
                        <div className={styles.value}>{t('common.noResults')}</div>
                      </div>
                    </li>
                )
                /*:
                  (
                    <>
                      { outputAirport(null, 0, false, true, 'popular', 'Популярные направления') }
                      {
                        name === 'from' ?
                          popularTo.map((item, index) => (
                            outputAirport(item, index, true, false, 'airport')
                          ))
                        :
                          popularBack.map((item, index) => (
                            outputAirport(item, index, true, false, 'airport')
                          ))
                      }
                    </>
                  )*/
              }
            </ul>
          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default Autocomplete;
