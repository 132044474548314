import { FunctionComponent, useEffect } from 'react';
import MainLogo from './MainLogo/MainLogo';
import MainNavigation from './MainNavigation/MainNavigation';
import LoginButton from './LoginButton/LoginButton';
import styles from './Header.module.scss';
import { useAppDispatch } from '../../store/store';
import { switchMenu, userSelector } from '../../store/slices/user';
import { useSelector } from 'react-redux';
import MobileNavigation from './MobileNavigation/MobileNavigation';
import useIsMobile from '../../utils/useIsMobile';
import { useSearchParams, useLocation } from 'react-router-dom';
import { setCookie, deleteCookie } from '../../utils/cookies';
import { saveToken, saveUser } from '../../store/slices/user';
import { useLazyGetPersonalInfoQuery } from '../../store/api/userService';
import LanguageButton from "./LanguageButton/LanguageButton";
import { routes } from '../../constants/routes';
import { useLangNavigate } from '../../utils/langNavigate';

type HeaderProps = {
  variant?: 'light' | 'dark';
};

const Header: FunctionComponent<HeaderProps> = ({ variant = 'dark' }) => {
  const dispatch = useAppDispatch();
  const { isMenuOpened, isMobileLKMenuOpened } = useSelector(userSelector);
  const menuIcon = isMenuOpened ? '/icons/close.svg' : '/icons/burger.svg';
  variant = isMenuOpened ? 'light' : variant;
  const style = variant === 'dark' ? styles.headerDark : styles.headerLight;
  const isMobile = useIsMobile(1025);
  const [getPersonalInfo] = useLazyGetPersonalInfoQuery();
  const href = useLocation();
  const langNavigate = useLangNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('bearer_token');

  useEffect(() => {
    const logout = () => {
      deleteCookie('token');
      //if (href.pathname.match(/^\/lk/) || href.pathname.search(/code=/)) navigate('/');
      if (
        href.pathname.match(/^\/lk/) &&
        !(href.pathname.indexOf(routes.ordersSuccess) > 0 || href.pathname.indexOf(routes.ordersFail) > 0)
      ) {
        langNavigate(routes.home);
      }
    };

    if (!token) {
      return;
    }

    setSearchParams({});

    setCookie('token', token ?? ' ');
    dispatch(saveToken({ token: token ?? ' ' }));
    getPersonalInfo({ token: token ?? ' ' })
      .unwrap()
      .then(user => {
        dispatch(saveUser({ user }));
      })
      .catch(() => {
        logout();
        console.log('error logout');
      });
  }, [token]);

  const handleSwitchMenu = () => {
    if (!isMenuOpened) {
      document.body.className = 'hidden';
    } else {
      document.body.classList.remove('hidden');
    }
    dispatch(switchMenu({ value: !isMenuOpened }));
  };

  const handleSwitchMobileLKMenu = () => {
    langNavigate(routes.lk)
  };

  return (
    <header className={style}>
      <div className={styles.headerContent}>
        <MainLogo variant={variant} />
        <MainNavigation variant={variant} />
        <div className={styles.buttonHeader}>
          <LanguageButton variant={variant} />
          <LoginButton variant={variant} onClick={isMobile ? handleSwitchMobileLKMenu : null} />
          <button type="button" className={styles.iconBurger} onClick={handleSwitchMenu}>
            <img className={styles.img} alt="" src={menuIcon} />
          </button>
        </div>
        {isMenuOpened && (
          <MobileNavigation
            handleSwitchMenu={handleSwitchMenu}
            handleSwitchMobileLKMenu={isMobile ? handleSwitchMobileLKMenu : null}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
