import { FunctionComponent } from 'react';
import IconButton from '../IconButton/IconButton';
import styles from './Footer.module.scss';
import { useSelector } from 'react-redux';
import { filterSelector } from '../../store/slices/filter';
import { useTranslation } from 'react-i18next';
import { routes } from '../../constants/routes';
import { links } from '../../constants/links';
import { LangLink } from '../LangLink/LangLing';

const Footer: FunctionComponent = () => {
  const { isFilterOpen } = useSelector(filterSelector);
  const { t } = useTranslation();

  if (isFilterOpen) {
    return null;
  }
  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.links}>
          <div className={styles.logo}>
            <img className={styles.icon} alt="" src={t('images.kupitripLogo')} />
            <div>{t('footer.onlineServiceForBooking')}</div>
          </div>
          <div className={styles.frameLink}>
            <section className={styles.footerSection}>
              <div className={styles.footerSectionHeader}>{t('footer.help')}</div>
              <div className={styles.footerSectionItems}>
                <div className={styles.footerSectionItem}>
                  <LangLink to={routes.help}>{t('footer.popularQuestions')}</LangLink>
                </div>
                <div className={styles.footerSectionItem}>
                  <a href="#questions">{t('footer.hotels')}</a>
                </div>
              </div>
              <div className={styles.footerSectionHeader}
                   style={{ marginTop: '1.5rem' }}>{t('footer.partnership')}</div>
              <div className={styles.footerSectionItems}>
                <div className={styles.footerSectionItem}>
                  <LangLink to={routes.partnership}>{t('footer.partnershipProgram')}</LangLink>
                </div>
              </div>
            </section>
            <section className={styles.footerSection}>
              <div className={styles.footerSectionHeader}>{t('footer.forClients')}</div>
              <div className={styles.footerSectionItems}>
                <div className={styles.footerSectionItem}>
                  <LangLink to={routes.tariff}>{t('footer.tariff')}</LangLink>
                </div>
                <div className={styles.footerSectionItem}>
                  <LangLink to={routes.blog}>{t('footer.blog')}</LangLink>
                </div>
                <div className={styles.footerSectionItem}>
                  <LangLink to={routes.feedback}>{t('footer.feedback')}</LangLink>
                </div>
              </div>
            </section>
          </div>
          <div className={styles.frameSocial}>
            <div className={styles.frameSocialItem}>
              <div>{t('footer.weAreOnSocialNetworks')}</div>
              <div className={styles.socialNetworks}>
                <a href={links.kupitriponline}>
                  <IconButton
                    className={styles.telegramButton}
                    icon="/icons/vk-footer.svg" />
                </a>
                <a href={links.kupitriponline}>
                <IconButton
                  className={styles.telegramButton}
                  icon="/icons/telegram.svg" />
              </a>
              </div>
            </div>
            <div className={styles.frameSocialItem}>
              <div>{t('footer.weAccept')}</div>
              <div className={styles.socialNetworks}>
                <img className={styles.visaIcon} alt="" src="/icons/visa.svg" />
                <img className={styles.mcIcon} alt="" src="/icons/masterCard.svg" />
                <img className={styles.mirIcon} alt="" src="/icons/mir.svg" />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.copyright}>
          <div>
            <div>{t('footer.kupiTrip')}{new Date().getFullYear()}</div>
            <br />
            <a className={styles.linkDelaemIt} href={links.delaem_it}>{t('footer.delaemIt')}</a>
          </div>
          <div className={styles.oferta}>
            <LangLink to={routes.safety_return_policy}>{t('footer.safetyReturnPolicy')}</LangLink>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
