import { ClickAwayListener, TextField } from '@mui/material';
import { useState, ChangeEventHandler, useEffect, KeyboardEventHandler, RefObject, useRef } from 'react';
import { useSelector } from 'react-redux';

import { Airport, City, isAirport } from '../../types/ticket';
// import { useSearchAirportsQuery } from '../../store/api/searchService';
import { useSearchHotelsQuery } from '../../store/api/searchHotelService';
import { useAppDispatch } from '../../store/store';
// import { searchSelector, setSearch } from '../../store/slices/search';
import { useDebounce } from '../../utils/useDebounce';
import styles from './AutocompleteHotels.module.scss';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { Cities } from '../../types/cities';
import { searchHotelSelector, setSearchHotels } from '../../store/slices/searchHotels';

export type SelectOptionType = Cities;

type Props = {
  className?: string;
  name: 'to' | 'from';
  label?: string;
  value?: string;
  error?: string;
  clearError?: () => void;
  hotels?: boolean
};

const AutocompleteHotels = ({ label, name, error, clearError, hotels }: Props) => {
  const dispatch = useAppDispatch();
  // const search = useSelector(searchSelector);
  const searchHotels = useSelector(searchHotelSelector);


  const textRef = useRef<HTMLInputElement | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const debouncedValue = useDebounce((isUpdating ? value : (textRef?.current?.value.split(',')[0].trim() || value)), 300);



  // const searchValue = searchHotels[name]?.name ? (searchHotels[name]?.name + (search[name]?.city && search[name]?.city !== search[name]?.name ? ', ' + search[name]?.city : '')) : '';
  const searchValue = searchHotels?.city ? searchHotels?.city : '';


  // const airports = useSearchAirportsQuery(debouncedValue.toLowerCase() || '');
  const cities = useSearchHotelsQuery(debouncedValue.toLowerCase() || '');
  const [options, setOptions] = useState([]);


  const { t } = useTranslation();
  const isRussianLanguage = i18n.language.startsWith('ru');

  useEffect(() => {
    const newValue = isRussianLanguage ?
      searchHotels?.city?.city ?? searchHotels?.city?.city ?? ''
      :
      searchHotels?.city?.city_en ?? searchHotels?.city?.city_en ?? '';

    setValue(newValue);
    if (textRef) {
      // @ts-ignore
      textRef.current.value = newValue;
    }
  }, [searchHotels]);

  useEffect(() => {
    setOptions(cities.data || []);
  }, [cities.data]);

  const handleChange = (v: SelectOptionType | null) => {
    const inputValue = isRussianLanguage ?
      (v?.city ? (v?.city + (v?.city && v?.city !== v?.city ? ', ' + v?.city : '')) : '')
      :
      (v?.city_en ? (v?.city_en + (v?.city_en && v?.city_en !== v?.city_en ? ', ' + v?.city_en : '')) : '');
    if (textRef?.current) {
      textRef.current.value = inputValue;
    }
    if (v) {
      dispatch(setSearchHotels(v));
    }
    setValue(v?.city || '');
    setIsUpdating(true);
    handleClose();
  };
  const handleFocus = () => {
    handleOpen();
    setIsUpdating(true);
    clearError && clearError();
  };
  const handleOpen = () => setIsOpen(true);
  const handleClose = (e?: MouseEvent | TouchEvent) => {
    if (textRef?.current) {
      if (e?.target === textRef.current) return;
      setIsOpen(false);
      setIsUpdating(false);
      setOptions([]);
    }
  };
  const handleUnFocus = (e?: MouseEvent | TouchEvent) => {
    if (textRef?.current) {
      if (e?.target === textRef.current) return;
      // setIsOpen(false);
      setIsUpdating(false);
      // setOptions([]);
    }
  };

  const handleStartEdit = () => {
    setIsUpdating(true);
    handleOpen();
  };

  const handleTextChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = e => {
    const { value } = e.currentTarget;
    const clearValue = value.replace(/[^а-яА-Яa-zA-Z/-]/gi, '');
    e.currentTarget.value = clearValue;
    setValue(clearValue);
    if (!clearValue.length) {
      // dispatch(setSearch({ [name]: null }));

    }
  };
  const handleKey: KeyboardEventHandler = e => {
    if (e.key == 'Tab') handleClose();
    if (e.key == 'Escape') handleClose();
  };


  const outputCities = (item: Cities | null, index: number = 0, click: boolean = true, title: boolean = false, indexKey: string = '', titleText: string = '') => {
    return (
      <li
        className={styles.option}
        onClick={() => click && item ? handleChange(item) : null}
        style={{ minHeight: item && (item) ? 55 : 30 }}
        key={indexKey !== '' ? (indexKey + '_' + index) : index}
      >
        {item ? (
            <>
              <div className={styles.title}>
                <div className={styles.value}>{isRussianLanguage ? item.city : item.city_en}</div>
                {item.country ? <div
                  className={styles.description}>{isRussianLanguage ? item.country.country : item.country.country_en}</div> : null}
              </div>
              <div className={styles.code}>{item.code}</div>
            </>
          )
          : titleText}
      </li>
    );
  };

  return (
    <div className={clsx(styles.root + ' autocomplete-wrapper-' + name, error ? styles.withError : '')}>
      <ClickAwayListener onClickAway={handleUnFocus}>
        <div>
          <TextField
            variant="filled"
            classes={{ root: styles.textField }}
            inputRef={textRef}
            InputProps={{

              classes: { root: styles.inputRoot },
              sx: hotels ? {
                '&.Mui-focused': {
                  border: '1px solid var(--purple-6100FF) !important'

                }
              } : {}

            }}
            InputLabelProps={{ shrink: isUpdating || !!value || !!searchValue || !!error }}
            // value={isUpdating ? null : searchValue}
            label={label}
            onChange={handleTextChange}
            onClick={handleStartEdit}
            onKeyDown={handleKey}
            onFocus={handleFocus}
            defaultValue={searchValue}
          />
          <span className={clsx(styles.withErrorPlaceholder)}>{error}</span>

        </div>

      </ClickAwayListener>
      {isOpen && textRef?.current?.value !== '' && (
        <ClickAwayListener onClickAway={handleClose}>
          <div className={styles.containerPaper}>
            <div className={styles.paper}>
              <ul className={styles.listbox}>
                {/*{ value.trim().length > 0 ?*/}
                {value.trim().length > 0 &&
                  (
                    options && options.length
                      ?
                      options.map((item: Cities, index) => {
                        return outputCities(item, index, true, true);

                      })
                      :
                      <li className={styles.option} style={{ minHeight: 30 }} key="noResults">
                        <div className={styles.title}>
                          <div className={styles.value}>{t('common.noResults')}</div>
                        </div>
                      </li>
                  )
                }
              </ul>
            </div>

          </div>
        </ClickAwayListener>
      )}
    </div>
  );
};

export default AutocompleteHotels;
