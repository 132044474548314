import { FunctionComponent, useEffect, useState } from 'react';
import clsx from 'clsx';
import Button from '../Button';
import GuestSelect from '../Input/GuestSelect/GuestSelect';
import styles from './SearchHotelsBar.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  filterBackendData,
  saveBackendData,
  setPassengers as setSearchPassengers,
  setSearch,
  setType
} from '../../store/slices/search';
import { dateRangeSelectorHotels, setFromTo } from '../../store/slices/datePickerHotels';
import { useAppDispatch } from '../../store/store';
import { Passenger } from '../../models/Passenger';
import { getPassenger } from '../../utils/adapters';
import { IPassenger } from '../../types/passenger';
import { userSelector } from '../../store/slices/user';
import { useGetPassengersQuery } from '../../store/api/userService';
import useIsMobile from "../../utils/useIsMobile";
import { Airport, City } from '../../types/ticket';
import { useTranslation } from 'react-i18next';
import AutocompleteHotels from '../AutocompleteHotels/AutocompleteHotels';
import RangeDatePickerHotels from '../Input/RangeDatePickerHotels/RangeDatePickerHotels';
import { searchHotelSelector } from '../../store/slices/searchHotels';
import { useLangNavigate } from '../../utils/langNavigate';
import { resetSearchHotels } from '../../store/slices/searchHotels';
import { routes } from '../../constants/routes';

/**
 * find passengers of corrent type from user's passengers or create new;
 */
const createPassengers = (
  { adults, children, babies }: { adults: number; children: number; babies: number },
  userPassengers: IPassenger[]
) => {
    const _passengers = [...userPassengers];
    const adultPassengers = new Array(adults)
        .fill(0)
        .map(() => getPassenger(_passengers, new Passenger({ type: 'adult' }).getPassenger()));
    const childPassengers = new Array(children)
        .fill(0)
        .map(() => getPassenger(_passengers, new Passenger({ type: 'child' }).getPassenger()));
    const babyPassengers = new Array(babies)
        .fill(0)
        .map(() => getPassenger(_passengers, new Passenger({ type: 'baby' }).getPassenger()));

    const r = [...adultPassengers, ...childPassengers, ...babyPassengers];
    let result: IPassenger[] = [];
    r.map((p, index) => {
        let clone = Object.assign({}, p);
        clone.id = index + 1;
        result.push(clone);
    });
    return result;
};

interface Props {
  className?: string;
  defaultOpen?: boolean;
}

const SearchHotelsBar: FunctionComponent<Props> = ({ className, defaultOpen = false }) => {
  const href = useLocation();
  const navigate = useLangNavigate();
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const [fromError, setFromError] = useState('');
  const [toError, setToError] = useState('');
  const [dateError, setDateError] = useState('');
  const [loaded, setPageLoaded] = useState(false);
  const { from: dateFrom, to: dateTo } = useSelector(dateRangeSelectorHotels);
  const { user, token } = useSelector(userSelector);
  const saved = localStorage.getItem('search_data');
  const{t} = useTranslation()
  const {dateStart, dateEnd, city, persons_count} = useSelector(searchHotelSelector)


  useEffect(() => {
      if (!loaded) {
          if (href.pathname.includes('/search')) {
              if (saved) {
                  const fields = JSON.parse(saved);
                  dispatch(setSearch({ from: fields.from, to: fields.to }));
                  dispatch(setFromTo({ from: (fields.date ?? null), to: (fields.date_back ?? null) }));
                  dispatch(setType({ type: fields. type }));
                  dispatch(setSearchPassengers({ adults: fields.adults, children: fields.children, babies: fields.babies }));
                  if (fields.filters) {
                      for (const [key, value] of Object.entries(fields.filters)) {
                          dispatch(filterBackendData({ key, value }));
                      }
                  }

                  const tickets = localStorage.getItem('tickets_data');
                  if (tickets) {
                      dispatch(saveBackendData({ data: JSON.parse(tickets) }));
                  }
              }
          }
          setPageLoaded(true);
      }
  }, [ saved, loaded ]);

  const expandMobile = () => {
    setIsOpen(true);
  };

  const handleSearch = () => {
    if(city && dateStart &&dateEnd){
      navigate(routes.hotels_booking);
    }
    if (city === null){
      setFromError(t('searchBarHotels.selectDepartureCity'))
    }
    if (!dateEnd){
      setToError(t('searchBarHotels.selectYourArrivalCity'));
    }
    if (!dateStart || !dateEnd){
      setDateError(t('searchBarHotels.selectDate'));
    }
    if (!dateStart || !dateEnd) return;
    setIsOpen(false);

  };



  const searchItems = (
    <>
      <div className={styles.searchItem}>
        <AutocompleteHotels
          //TODO добавить CITY
            name="from"
            label={t('searchBarHotels.selectDepartureCity')}
            error={fromError}
            clearError={() => setFromError('')}
            hotels={true}
        />
      </div>
      <div className={styles.divider} />
      <div className={clsx(styles.searchItem, styles.datepicker)}>
        <RangeDatePickerHotels
            hotels={true}
            labelFrom={t('searchBarHotels.checkInDate')}
            labelTo={t('searchBarHotels.returnDate')}
            error={dateError}
            clearError={() => setDateError('')}
        />
      </div>
      <div className={styles.divider} />
      <div className={styles.searchItem}>
        <GuestSelect />
      </div>
    </>
  );

  return (
    <div className={clsx(styles.searchField, className)}>
      <div className={styles.searchDesktop + ' autocomplete-desktop'}>{searchItems}</div>
      {!isOpen ? (
        <div className={styles.searchMobile} onClick={expandMobile}>
        </div>
      ) : (
        <div className={styles.searchMobileExpand + ' autocomplete-mobile'}>{searchItems}</div>
      )}
      <Button hotels={true} className={styles.searchButton} onClick={handleSearch}>
        {t('searchBarHotels.searchButton')}
      </Button>
      {isOpen ? (
        <Button hotels={true} className={styles.searchButtonMobile} onClick={handleSearch}>
          {t('searchBarHotels.searchButton')}
        </Button>
      ) : null}
    </div>
  );
};

export default SearchHotelsBar;
