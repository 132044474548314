import { ReactNode, useEffect, useState } from 'react';
import Card from '../Card/Card';
import { Controller, useFormContext } from 'react-hook-form';
import SuggestionInput from '../Suggestion/Suggestion';
import styles from './Guests.module.scss';
import {
  checkStringContainLetters,
  combineValidators,
  englishNamePattern,
  maxLength,
  validateStartEndSpaces
} from '../../utils/formValidation';
import { addGuest, searchHotelSelector, setGuest } from '../../store/slices/searchHotels';
import { useAppDispatch } from '../../store/store';
import { useSelector } from 'react-redux';
import i18n from 'i18next';
import Input from '../Input/Input';
import { Guest } from '../../types/guest';
import { useTranslation } from 'react-i18next';


interface GuestsProps {
  className?: string;
  children?: ReactNode;
  guests: number;
}


export const Guests = (props: GuestsProps) => {
  const {
    className,
    children,
    guests,
    ...otherProps
  } = props;

  const dispatch = useAppDispatch();
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();

  const [errorsGuests, setErrorsGuests] = useState<Guest[]>();
  const { t } = useTranslation();
  useEffect(() => {
    if (errors?.guests) {
      setErrorsGuests(errors.guests as Guest[]);
    }
  }, [errors.guests]);


  const { persons } = useSelector(searchHotelSelector);
  const rules = {
    name: {
      required: i18n.t('contactDetails.required'),
      maxLength,
      pattern: englishNamePattern,
      validate: combineValidators([validateStartEndSpaces, checkStringContainLetters])
    },
    surname: {
      required: i18n.t('contactDetails.required'),
      maxLength,
      pattern: englishNamePattern,
      validate: combineValidators([validateStartEndSpaces, checkStringContainLetters])
    }
  };
  const handleSanitize = (event: any, field: any) => {
    const sanitizedValue = event.target.value
      .replace(/[^a-zA-Z-]/g, '')
      .replace(/ -/g, '-')
      .replace(/- /g, '-')
      .replace(/ +/g, ' ')
      .replace(/-+/g, '-')
      .toUpperCase();
    return field.onChange(sanitizedValue);
  };

  const handleGuestChange = (index: number, field: string, value: string) => {


    const guest = { ...getValues(`guests.${index}`), [field]: value.toUpperCase() };
    if (persons.length - 1 < index) {
      dispatch(addGuest(guest));
    } else {
      dispatch(setGuest({ index, guest }));
    }

  };


  return (
    <Card
      {...otherProps}
    >

      {guests && [...Array(guests)].map((_, index) => (
        <div key={index + 1}>
          <div className={styles.header}>
            <img className={styles.imgAvatar} alt="avatar-guest-order" src="/icons/avatar-guest-order.svg" />
            {t('placeOrderHotelPage.typeHotel.guest')} {index + 1}
          </div>
          <div className={styles.container}>
            <div className={styles.input}>
              <Controller
                name={`guests.${index}.last_name`}
                control={control}
                rules={rules.surname}
                render={({ field }) => (
                  <Input
                    hotels={true}
                    className={styles.input}
                    {...field}
                    error={errorsGuests && errorsGuests[index]?.last_name}
                    value={field.value}
                    type="text"
                    label={t('placeOrderHotelPage.typeHotel.labelGuestLast')}
                    onChange={(e) => {
                      handleSanitize(e, field);
                      handleGuestChange(index, 'last_name', e.target.value);
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.input}>
              <Controller
                name={`guests.${index}.first_name`}
                control={control}
                rules={rules.name}
                render={({ field }) => (
                  <SuggestionInput
                    hotels={true}
                    {...field}
                    error={errorsGuests && errorsGuests[index]?.first_name}

                    field={field}
                    value={field.value}
                    type="text"
                    label={t('placeOrderHotelPage.typeHotel.labelGuestFirst')}
                    option_search={'surname'}
                    onChange={(e) => {
                      handleSanitize(e, field);
                      handleGuestChange(index, 'first_name', e.target.value);
                    }}
                  />
                )}
              />
            </div>
          </div>
        </div>
      ))}
    </Card>
  );
};
